import React, { Component, Suspense } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import './assets/scss/index.scss';
import Routes from './Routes';

const browserHistory = createBrowserHistory();

export default class App extends Component {
  render() {
    return (
     <Suspense fallback={<LinearProgress />}>
      <ThemeProvider theme={theme}>
        <Router history={browserHistory}>
          <Routes />
        </Router>
      </ThemeProvider>
    </Suspense>
    );
  }
}